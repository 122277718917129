export function csvJSON(csv) {
  var lines = csv.split("\n");

  var result = [];

  // NOTE: If your columns contain commas in their values, you'll need
  // to deal with those before doing the next step
  // (you might convert them to &&& or something, then covert them back later)
  // jsfiddle showing the issue https://jsfiddle.net/
  // var headers = lines[0].split(",");
  var headers = lines[0].split("\t");

  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    // var currentline = lines[i].split(",");
    var currentline = lines[i].split("\t");

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  //return result; //JavaScript object
  var json = result; //JSON.stringify(result); //JSON

  json.forEach((obj, index, array) => {
    var key,
      keys = Object.keys(obj);
    var n = keys.length;
    var newobj = {};
    while (n--) {
      key = keys[n];
      var newKey = key.charAt(0).toLowerCase() + key.slice(1);
      newKey = newKey.trim().replace(/ /g, "");
      newobj[newKey] = obj[key].replace("\r", "");
    }
    json[index] = newobj;
  });

  return json;
}
