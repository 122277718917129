/*eslint-disable*/
import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Webcam from "react-webcam";
import axios from "axios";
// reactstrap components
import {
  Button,
  Modal,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Alert,
  Row,
  Badge,
  NavItem,
  NavLink,
  Nav,
  Col,
} from "reactstrap";
import moment from "moment";
// import Switch from "react-bootstrap-switch";
import Switch from "react-switch";

import { setGlobalCssModule } from "reactstrap/lib/utils";
import { appendText } from "gulp-append-prepend";
// core components

if (typeof Number.prototype.toRad === "undefined") {
  Number.prototype.toRad = function () {
    return (this * Math.PI) / 180;
  };
}

// const googleSheet =
// "https://docs.google.com/spreadsheets/d/e/2PACX-1vQBY4JYVBJ7d2dEjFBL1UcYWojO6O_v49_PONeShDrb5LX85Aexy_0juU02Iajsx1OJP3yJH-zCHSVw/pub?output=tsv";

const clockInForm =
  "https://docs.google.com/forms/d/e/1FAIpQLSexr0BP3n4pDKgi4qKxOxLGSnWofsa77nPK8KWNp-QqWNvC6Q/viewform";
// "https://docs.google.com/forms/d/1SfH_DHHCxfCafrGGfAPJSKiMMz9AMxv6rurkiA_IIYc";
// "https://docs.google.com/forms/u/3/d/e/1FAIpQLSexr0BP3n4pDKgi4qKxOxLGSnWofsa77nPK8KWNp-QqWNvC6Q";
const clockOutForm =
  "https://docs.google.com/forms/d/e/1FAIpQLSeFJsi56CPtvc02l0SEct4VJSf9OS9nnfM6yHPJ5dc9VoX2sA/viewform";

var today = new Date();
// today.setMonth(today.getMonth() - 1);

function StaffPage(props) {
  let pageHeader = React.createRef();

  const [customers, setCustomers] = React.useState([]);
  const [rewards, setRewards] = React.useState([]);

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  React.useEffect(() => {
    checkStaffCheckedIn();
    loadCustomerSales();
    loadRewards();
    if (user.message && user.message !== "") setNotification(user.message);
  }, []);

  function handleSwitch(state) {
    if (state === false) {
      setAction("Clock Out");
    } else {
      setAction("Clock In");
    }
  }
  function loadRewards() {
    const GOOGLE_FORM_ACTION_URL = googleSheet + "&gid=" + department.rewards;
    // "https://spreadsheets.google.com/feeds/cells/1umo5dgBSYh1XMFI2t4WOHWdAQRd_nF9YCCVYhEYRk8s/3/public/full?alt=json";
    axios
      .get(GOOGLE_FORM_ACTION_URL)
      .then((res) => {
        var json = csvJSON(res.data);
        setRewards(json);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function loadCustomerSales() {
    // "https://spreadsheets.google.com/feeds/cells/1umo5dgBSYh1XMFI2t4WOHWdAQRd_nF9YCCVYhEYRk8s/3/public/full?alt=json";
    // console.log(
    //   "loadCustomerSales",
    //   "https://cors.iconstudio.co/" + department.api
    // );
    axios
      .get("https://cors.iconstudio.co/" + department.api)
      .then((res) => {
        var json = res.data.customers ? res.data.customers : res.data; //JSON.parse(res.data);
        // console.log("json:", json);
        setCustomers(json);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function distance(lon1, lat1, lon2, lat2) {
    var R = 6371; // Radius of the earth in km
    var dLat = (lat2 - lat1).toRad(); // Javascript functions in radians
    var dLon = (lon2 - lon1).toRad();
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1.toRad()) *
        Math.cos(lat2.toRad()) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km

    return d;
  }

  const [breakTime, setBreakTime] = React.useState("00:30");
  const [notification, setNotification] = React.useState("");
  const [action, setAction] = React.useState("Clock In");
  const [staff, setStaff] = React.useState(props.name);
  const [user, setUser] = React.useState(props.user);
  const [nickname, setNickname] = React.useState(
    props.nickname !== "" ? props.nickname : props.name
  );
  const [department, setDepartment] = React.useState(props.department);
  const [BG, setBG] = React.useState(props.bg);
  const [color, setColor] = React.useState(props.color);
  const [submitting, setSubmitting] = React.useState(false);
  const [showCameraGif, setShowCameraGif] = React.useState(true);
  const [image, setImage] = React.useState(props.image);
  const [img, setImg] = React.useState("");
  const [checkedIn, setCheckedIn] = React.useState();
  const break_items = [];
  const [staffMembers, setStaffMembers] = React.useState(props.staffMembers);
  const [googleSheet, setGoogleSheet] = React.useState(props.googleSheet);

  for (let index = 0; index < 10; index++) {
    break_items.push(
      <option key={index} value={"00:" + (index * 15).toString()}>
        {index * 15} Minutes Break
      </option>
    );
  }

  function captureImage(img) {
    setImg(img);
    var imageName = staff + "_" + new Date().getTime().toString();
    setImage(imageName + ".png");
    uploadImage(img, imageName);
    // setSubmitting(false);

    setTimeout(() => {
      if (action === "Clock In") {
        clockIn(imageName + ".png");
      } else if (action === "Clock Out") {
        clockOut(imageName + ".png");
      }
    }, 1000);
  }

  function uploadImage(img, imageName) {
    const formData = {
      image: img,
      name: imageName,
      staff: staff,
      hash: "688787D8FF144C502C7F5CFFAAFE2CC588D86079F9DE88304C26B0CB99CE91C6",
    };
    let endpoint = "uploading_files_php.php";
    axios.post(endpoint, formData, {}).then((res) => {
      console.log("File uploaded!");
    });
  }

  function clockIn(img) {
    var clockInObj = {
      Name: staff,
      BreakTime: breakTime,
      Department: department.name,
      Location: props.loc,
      Image: img,
    };

    return fetch("https://cors.iconstudio.co/" + clockInForm + "?embedded=true")
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {
        var parser = new DOMParser();
        var htmlDoc = parser.parseFromString(data, "text/html");

        var form = htmlDoc.getElementsByTagName("form");
        var formResponseURL = form[0].action;

        var scripts = htmlDoc.getElementsByTagName("script");

        var formData = new FormData();
        var bodyData = {};

        for (let i = 0; i < scripts.length; i++) {
          var script = scripts[i];
          if (script.innerHTML.indexOf("FB_PUBLIC_LOAD_DATA_") > -1) {
            var formObjs = JSON.parse(
              script.innerHTML.split("=")[1].trim().replace(";", "")
            )[1][1];
            formObjs.map((inputItem, index, array) => {
              var code = inputItem[4][0][0];
              var label =
                inputItem[1].charAt(0).toUpperCase() + inputItem[1].slice(1);
              var label = label.trim().replace(" ", "");

              formData.append("entry." + code, clockInObj[label]);
            });
          }
        }

        return axios({
          method: "post",
          url: "https://cors.iconstudio.co/" + formResponseURL,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {
            //handle success
            setNotification(staff + " " + action + " Successful!");
            // setSubmitting(false);

            setTimeout(() => {
              window.location.reload();
            }, 5000);
            return true;
          })
          .catch(function (response) {
            //handle error
          });
      });
  }

  function clockOut(img) {
    var clockOutObj = {
      Name: staff,
      BreakTime: breakTime,
      Department: department.name,
      Location: props.loc,
      Image: img,
    };

    return fetch(
      "https://cors.iconstudio.co/" + clockOutForm + "?embedded=true"
    )
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {
        var parser = new DOMParser();
        var htmlDoc = parser.parseFromString(data, "text/html");

        var form = htmlDoc.getElementsByTagName("form");
        var formResponseURL = form[0].action;

        var scripts = htmlDoc.getElementsByTagName("script");

        var formData = new FormData();
        var bodyData = {};

        for (let i = 0; i < scripts.length; i++) {
          var script = scripts[i];
          if (script.innerHTML.indexOf("FB_PUBLIC_LOAD_DATA_") > -1) {
            var formObjs = JSON.parse(
              script.innerHTML.split("=")[1].trim().replace(";", "")
            )[1][1];
            formObjs.map((inputItem, index, array) => {
              var code = inputItem[4][0][0];
              var label =
                inputItem[1].charAt(0).toUpperCase() + inputItem[1].slice(1);
              var label = label.trim().replace(" ", "");

              formData.append("entry." + code, clockOutObj[label]);
            });
          }
        }

        return axios({
          method: "post",
          url: "https://cors.iconstudio.co/" + formResponseURL,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {
            //handle success
            setNotification(staff + " " + action + " Successful!");
            // setSubmitting(false);

            setTimeout(() => {
              window.location.reload();
            }, 5000);
            return true;
          })
          .catch(function (response) {
            //handle error
          });
      });
  }

  // function clockout2(img = image) {
  //   console.log("clock-out", JSON.stringify(img));
  //   const GOOGLE_FORM_USER = "entry.1691996927";
  //   const GOOGLE_FORM_USER_BREAK = "entry.1167999698";
  //   const GOOGLE_FORM_USER_GPS = "entry.2053177853";
  //   const GOOGLE_FORM_USER_DEPT = "entry.1120885395";
  //   const GOOGLE_FORM_USER_IMG = "entry.1333270044";
  //   const GOOGLE_FORM_ACTION_URL =
  //     "https://docs.google.com/forms/u/3/d/e/1FAIpQLSeFJsi56CPtvc02l0SEct4VJSf9OS9nnfM6yHPJ5dc9VoX2sA/formResponse?embedded=true";
  //   const CORS_PROXY = "https://cors.iconstudio.co/";

  //   const formData = new FormData();
  //   formData.append(GOOGLE_FORM_USER, staff);
  //   formData.append(GOOGLE_FORM_USER_BREAK, "00:" + breakTime);
  //   formData.append(GOOGLE_FORM_USER_DEPT, department);
  //   formData.append(GOOGLE_FORM_USER_GPS, JSON.stringify(props.loc));
  //   formData.append(GOOGLE_FORM_USER_IMG, JSON.stringify(img));
  //   axios
  //     .post(GOOGLE_FORM_ACTION_URL, formData)
  //     .then(() => {
  //       setNotification("Submitted");
  //     })
  //     .catch((e) => {
  //       setNotification(staff + " " + action + " Successful!");
  //       // setSubmitting(false);

  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 5000);
  //     });
  // }

  function submit() {
    // setSubmitting(true);
    // camera.startCamera();
    // console.log(camera.takeSnapshot());
    var gps = props.department.gps; //[10.8087144, 106.7275147];
    var dept_temp = props.departments.filter(
      (dept, index, array) => dept.name === department.name
    );
    if (dept_temp.length > 0) gps = dept_temp[0].gps;
    if (staff === "") {
      setNotification("Select a Staff member");
    } else if (department.name === "") {
      setNotification("Select a department");
    } else if (
      distance(
        parseFloat(gps[0]),
        parseFloat(gps[1]),
        props.loc[0],
        props.loc[1]
      ) > dept_temp[0].location_limit &&
      screen.width < 400
    )
      setNotification("You need to clock in at work! GPS too far away!");
    else {
      setSubmitting(true);
      setTimeout(() => {
        setShowCameraGif(false);
      }, 3500);
    }
  }

  function checkStaffCheckedIn() {
    var checkIn = props.latestIn.filter((item, index, array) => {
      if (item.name === staff) {
        var tday = new Date().setHours(0, 0, 0, 0);

        var thatDay = moment(item.timestamp, "DD/MM/YYYY")
          .toDate()
          .setHours(0, 0, 0, 0);
        // console.log(today, thatDay);
        return tday === thatDay;
      }
    });
    // console.log(checkIn);

    var checkOut = props.latestOut.filter((item, index, array) => {
      if (item.name === staff) {
        var tday = new Date().setHours(0, 0, 0, 0);
        var thatDay = moment(item.timestamp, "DD/MM/YYYY")
          .toDate()
          .setHours(0, 0, 0, 0);
        // console.log(today, thatDay);
        return tday === thatDay;
      }
    });
    // console.log("checkOut:", checkOut);
    if (checkIn.length > 0 && checkIn.length > checkOut.length) {
      setCheckedIn(true);
      setAction("Clock Out");
    } else setCheckedIn(false);
  }

  if (typeof checkedIn === "undefined") {
    return (
      <>
        <div
          className="page-header clear-filter"
          // filter-color="blue"
          style={{
            backgroundColor:
              "linear-gradient(0deg, rgba(44, 44, 44, 0.2), " +
              hexToRGB(color, 0.2) +
              ")",
          }}
        >
          <div
            className="page-header-image"
            style={{
              backgroundImage: "url(" + BG + ")",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Loader
              type="Puff"
              color={color}
              height={100}
              width={100}
              // timeout={3000} //3 secs
              style={{ marginTop: "20%" }}
            />
          </div>
        </div>
      </>
    );
  }

  var timestamp = today.getTime();

  return (
    <>
      {customers && customers.length > 0 && user.work === "fulltime" && (
        <ModalChart
          department={department}
          rewards={rewards}
          customers={customers}
          color={color}
          staff={staff}
        />
      )}
      <div
        className="page-header clear-filter"
        // filter-color={color ? color : "blue"}
        style={{
          // backgroundColor:

          backgroundImage:
            "linear-gradient(0deg,  rgba(44, 44, 44, 0.2)," +
            hexToRGB(color, 0.5) +
            ")",
          // "linear-gradient(0deg, rgba(44, 44, 44, 0.2), rgba(3, 161, 224, 0.6))",
          // "linear-gradient(0deg, rgba(44, 44, 44, 0.2), " +
          // hexToRGB(color, 0.2), //</> +
          // ")",
        }}
      >
        <Alert
          color={
            notification.indexOf("Successful") > -1 ? "success" : "warning"
          }
          isOpen={notification !== ""}
          timeout={5000}
          style={{ zIndex: 100000 }}
        >
          <Container>
            <div className="alert-icon">
              <i className="now-ui-icons travel_info"></i>
            </div>
            {notification}
            <button
              type="button"
              className="close"
              onClick={() => setNotification("")}
            >
              <span aria-hidden="true">
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </Container>
        </Alert>
        {submitting && (
          <>
            <div
              className="page-header clear-filter"
              // filter-color="blue"
              style={{
                backgroundColor:
                  "linear-gradient(0deg, rgba(44, 44, 44, 0.2), " +
                  hexToRGB(color, 0.2) +
                  ")",
              }}
            >
              <div
                className="page-header-image"
                style={{
                  backgroundImage: "url(" + BG + ")",
                  bacckgroundSize: "100% auto",
                  backgroundRepeat: "no-repeat",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80%",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {showCameraGif ? (
                  <div
                    style={{
                      position: "relative",
                      background: "url(camera.gif)",
                      backgroundSize: "100% auto",
                      backgroundPosition: "center center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        fontSize: 64,
                        textAlign: "center",
                        position: "relative",
                        width: "100%",
                        top: 100,
                      }}
                    >
                      Smile For the Camera
                    </span>
                    {/* <img src="camera.gif" /> */}
                  </div>
                ) : (
                  <div>
                    <div
                      className="renlian"
                      style={{
                        // marginTop: -50,
                        background:
                          "url(" +
                          "/uploads/" +
                          staff +
                          ".png?=" +
                          timestamp +
                          ")",
                      }}
                    >
                      <div className="box">
                        <div className="line"></div>
                        <div className="bottom"></div>
                      </div>
                    </div>
                    }
                    <Loader
                      type="Puff"
                      color={color}
                      height={100}
                      width={100}
                      // timeout={3000} //3 secs
                      style={{ flex: 1, marginTop: "20%" }}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + BG + ")",
          }}
          ref={pageHeader}
        ></div>

        <Container style={{ marginTop: 30 }}>
          <div className="content-center brand">
            <div className="logo-container">
              <img
                alt="..."
                src={
                  props.image
                    ? props.image
                    : "/uploads/" + staff + ".png?=" + timestamp
                }
                // props.department.logo}
                style={{
                  width: 175,
                  height: 175,
                  borderRadius: 87,
                  backgroundColor: color,
                  border: color + " solid 5px",
                }}
              ></img>
            </div>
            <div className="bosses">
              <img src="./bosses.png" style={{ height: 350 }} />
            </div>
            <div className="bubble bubble-bottom-left" style={{ color: color }}>
              {welcome()} <span style={{ color: "#999" }}> {nickname}</span>
              <br />
              <div>
                <div>
                  <span style={{ color: "#999" }}>
                    <span style={{ color: "#fff", fontSize: 32 }}>
                      {today.getDay() === 0 ? (
                        <span>
                          {"Working on a Sunday!? Thanks!"}
                          <br />
                        </span>
                      ) : today.getDay() === 5 ? (
                        <span>
                          {"HAPPY FRIDAY"}
                          <br />
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                    {action === "Clock In"
                      ? department.quotes_in[
                          Math.floor(
                            Math.random() * department.quotes_in.length
                          )
                        ]
                      : department.quotes_out[
                          Math.floor(
                            Math.random() * department.quotes_out.length
                          )
                        ]}
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                margin: 40,
              }}
            >
              <center>
                <div
                  style={{
                    borderRadius: 25,
                    backgroundColor: "rgba(255,255,255,0.7)",
                    padding: 10,
                    // paddingTop: 14,
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: 200,
                  }}
                >
                  <Switch
                    checked={action === "Clock In" ? true : false}
                    onChange={(el, state) => handleSwitch(el, state)}
                    onColor={color}
                    offColor={color}
                    onHandleColor={color}
                    handleDiameter={30}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.1)"
                    height={30}
                    width={120}
                    className="react-switch"
                    id="material-switch"
                    uncheckedIcon={
                      <div
                        style={{
                          position: "relative",
                          left: -34,
                          top: 5,
                          width: 70,
                          fontFamily: "Raleway",
                          height: "100%",
                          fontSize: 14,
                          color: "#fff",
                          paddingRight: 2,
                        }}
                      >
                        Clock Out
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          position: "relative",
                          width: 70,
                          left: 15,
                          top: 5,
                          height: "100%",
                          fontFamily: "Raleway",
                          fontSize: 14,
                          color: "#ff",
                          paddingRight: 2,
                        }}
                      >
                        Clock In
                      </div>
                    }
                  />
                  {/* <Switch
                    width={100}
                    height={75}
                    onChange={(el, state) => handleSwitch(el, state)}
                    offColor="warning"
                    bsSize="large"
                    defaultValue={action === "Clock In" ? true : false}
                    offText="Out"
                    labelWidth={200}
                    onColor="primary"
                    onText="In"
                  /> */}
                </div>
              </center>
            </div>
            {action === "Clock Out" ? (
              <div style={{ marginTop: 15 }}>
                <select
                  id=""
                  style={{
                    borderRadius: 5,
                    borderColor: color,
                    backgroundColor: color,
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: 18,
                    padding: 10,
                  }}
                  value={breakTime}
                  onChange={(e) => {
                    setBreakTime(e.target.value);
                  }}
                >
                  {break_items}
                </select>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Nav
                  style={{}}
                  className="nav-pills-info nav-pills-just-icons"
                  pills
                  role="tablist"
                >
                  {props.departments.map((depart, index, array) => (
                    <NavItem key={index}>
                      <NavLink
                        className={
                          department.name === depart.name ? "active" : ""
                        }
                        style={{
                          minWidth: 70,
                          height: 70,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor:
                            department.name === depart.name
                              ? color
                              : "rgba(255, 255, 255, 0.7)",
                          cursor: "hand",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setDepartment(depart);
                          setBG(depart.image);
                          setColor(depart.color);
                        }}
                      >
                        <img
                          src={depart.icon}
                          style={
                            department !== depart.name
                              ? { width: 25, marginTop: 5 }
                              : {
                                  width: 25,
                                  marginTop: 5,
                                  filter:
                                    "invert(100%) sepia(100%) saturate(1%) hue-rotate(289deg) brightness(105%) contrast(101%)",
                                }
                          }
                        />
                        <br />
                        <div
                          style={{
                            fontSize: 12,
                            fontFamily: "Raleway",
                            position: "relative",
                            top: 2,
                            lineHeight: "14px",
                            whiteSpace: "normal",
                          }}
                        >
                          {depart.name}
                        </div>
                      </NavLink>
                    </NavItem>
                  ))}
                  {/* <NavItem>
                    <NavLink
                      className={
                        department === "Cheesecake Cafe" ? "active" : ""
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setDepartment("Cheesecake Cafe");
                      }}
                    >
                      <svg
                        style={{ width: 25, marginTop: 5 }}
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fal"
                        data-icon="coffee-togo"
                        className="svg-inline--fa fa-coffee-togo fa-w-14"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="currentColor"
                          d="M432 64h-16l-23.16-46.31A32 32 0 0 0 364.22 0H83.78a32 32 0 0 0-28.62 17.69L32 64H16C7.16 64 0 71.16 0 80v64c0 8.84 7.16 16 16 16h18.67l26.89 322.66C62.94 499.24 76.8 512 93.44 512h261.11c16.64 0 30.51-12.76 31.89-29.34L413.33 160H432c8.84 0 16-7.16 16-16V80c0-8.84-7.16-16-16-16zm-77.44 416H93.44l-5.33-64h271.78l-5.33 64zm8-96H85.44L74.78 256h298.45l-10.67 128zm13.33-160H72.11l-5.33-64h314.45l-5.34 64zM416 128H32V96h19.78l32-64h280.45l32 64H416v32z"
                        ></path>
                      </svg>
                      <br />
                      <span
                        style={{ fontSize: 10, position: "relative", top: -5 }}
                      >
                        Cafe
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        department === "Cheesecake Orders" ? "active" : ""
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setDepartment("Cheesecake Orders");
                      }}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fal"
                        data-icon="birthday-cake"
                        className="svg-inline--fa fa-birthday-cake fa-w-14"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="currentColor"
                          d="M96 96c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40zm128 0c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40zm128 0c-17.75 0-32-14.25-32-32 0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40zm48 160h-32V112h-32v144h-96V112h-32v144h-96V112H80v144H48c-26.5 0-48 21.5-48 48v208h448V304c0-26.5-21.5-48-48-48zm16 224H32v-72.043C48.222 398.478 55.928 384 74.75 384c27.951 0 31.253 32 74.75 32 42.843 0 47.217-32 74.5-32 28.148 0 31.201 32 74.75 32 43.357 0 46.767-32 74.75-32 18.488 0 26.245 14.475 42.5 23.955V480zm0-112.374C406.374 359.752 394.783 352 373.5 352c-43.43 0-46.825 32-74.75 32-27.695 0-31.454-32-74.75-32-42.842 0-47.218 32-74.5 32-28.148 0-31.202-32-74.75-32-21.463 0-33.101 7.774-42.75 15.658V304c0-8.822 7.178-16 16-16h352c8.822 0 16 7.178 16 16v63.626z"
                        ></path>
                      </svg>
                      <span
                        style={{ fontSize: 10, position: "relative", top: -5 }}
                      >
                        Orders
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        department === "Bagel Brothers" ? "active" : ""
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setDepartment("Bagel Brothers");
                      }}
                    >
                      <svg
                        style={{ height: 30, marginTop: 5 }}
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fal"
                        data-icon="dot-circle"
                        className="svg-inline--fa fa-dot-circle fa-w-16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.663 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.663 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm0-296c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80zm0 128c-26.467 0-48-21.533-48-48s21.533-48 48-48 48 21.533 48 48-21.533 48-48 48z"
                        ></path>
                      </svg>
                      <span
                        style={{ fontSize: 10, position: "relative", top: -5 }}
                      >
                        Bagel
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={
                        department === "Cheesecake Bakery" ? "active" : ""
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setDepartment("Cheesecake Bakery");
                      }}
                    >
                      <div>
                        <svg
                          style={{ width: 25, marginTop: 5 }}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fal"
                          data-icon="hat-chef"
                          className="svg-inline--fa fa-hat-chef fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M416 32a95.17 95.17 0 0 0-57.73 19.74C334.93 20.5 298 0 256 0s-78.93 20.5-102.27 51.74A95.56 95.56 0 0 0 0 128c0 41.74 64 224 64 224v128a32 32 0 0 0 32 32h320a32 32 0 0 0 32-32V352s64-182.26 64-224a96 96 0 0 0-96-96zm0 448H96v-96h320zm0-128h-44.09L384 201.25a8 8 0 0 0-7.33-8.61l-16-1.28h-.65a8 8 0 0 0-8 7.37L339.8 352h-68.46V200a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v152H172.2l-12.27-153.3a8 8 0 0 0-8-7.37h-.65l-16 1.28a8 8 0 0 0-7.33 8.61L140.09 352H96S32 150.7 32 128a64.07 64.07 0 0 1 64-64 63.22 63.22 0 0 1 38.39 13.24l25.68 19.48 19.3-25.83C197.83 46.18 225.77 32 256 32s58.17 14.18 76.63 38.89l19.3 25.83 25.68-19.48A63.22 63.22 0 0 1 416 64a64.07 64.07 0 0 1 64 64c0 22.7-64 224-64 224z"
                          ></path>
                        </svg>
                        <span
                          style={{
                            fontSize: 10,
                            position: "relative",
                            top: -5,
                          }}
                        >
                          Bakery
                        </span>
                      </div>
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </div>
            )}
            <br />
            <br />
            <Button
              color="info"
              size="lg"
              style={{
                width: "90%",
                height: 100,
                fontSize: window.innerWidth < 480 ? 18 : 24,
                textTransform: "uppercase",
                backgroundColor: color,
              }}
              onClick={submit}
            >
              {action === "Clock In" ? (
                <span style={{ fontFamily: "Raleway" }}>
                  {department.name}
                  <br />
                  CLOCK IN - START SHIFT
                </span>
              ) : (
                <span style={{ fontFamily: "Raleway" }}>
                  {department.name}
                  <br />
                  CLOCK OUT - FINISH SHIFT
                </span>
              )}
            </Button>
          </div>
        </Container>
      </div>
      {submitting && <WebcamCapture captureImage={captureImage} />}
    </>
  );
}

export default StaffPage;

const WebcamCapture = (props) => {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  React.useEffect(() => {
    setTimeout(() => {
      capture();
    }, 3500);
  }, []);

  function capture() {
    const img = webcamRef.current.getScreenshot();
    props.captureImage(img);
  }

  return (
    <>
      <Webcam
        // style={{ display: "none" }}
        screenshotQuality={1}
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
      />
    </>
  );
};

export function ModalChart(props) {
  var { department, color, staff, customers, rewards } = props;

  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var labels = [];
  var customerValues = [];
  var total = 0;

  var day_count = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0
  ).getDate();

  if (department.month_reviews > 0)
    total = department.month_reviews * department.review_points;

  if (department.negative_points) total = total - department.negative_points;

  var dataValues = { customers: [], dates: [] };
  var month = parseInt(parseInt(today.getMonth()) + 1);

  // console.log("multiplier", parseFloat(department.rewards_multiplier));

  for (let index = 1; index <= 31; index++) {
    var dateString =
      today.getFullYear() +
      "-" +
      (today.getMonth() < 9 ? "0" + month : month) +
      "-" +
      (index < 9 ? "0" + index : index);

    var cust = customers.filter((value, index, array) => {
      return value.date === dateString;
    });

    if (cust.length > 0) {
      // console.log(
      //   "dept.rewards_type",
      //   department.rewards_type,
      //   parseInt(cust[0].total / parseFloat(department.rewards_multiplier))
      // );
      if (department.rewards_type == "total")
        total =
          total +
          parseInt(cust[0].total / parseFloat(department.rewards_multiplier));
      else
        total =
          total +
          parseInt(
            cust[0].customers * parseFloat(department.rewards_multiplier)
          );
    }

    dataValues.customers.push(total);
    dataValues.dates.push(dateString);
  }

  var maxCustomers = dataValues.customers[dataValues.customers.length - 1];
  // console.log(maxCustomers);
  var max = maxCustomers > 2000 ? maxCustomers + 200 : 3000;

  const data = {
    labels: dataValues.dates,
    datasets: [
      {
        label: months[today.getMonth()] + " " + department.name + " Points",
        data: dataValues.customers, // [5, 3, 4, 2, 5, 3, 3],
        borderColor: color,
        backgroundColor: color, //"rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: "top",
  //     },
  //     title: {
  //       display: true,
  //       text: department.name,
  //     },
  //   },
  //   scales: {
  //     y: {
  //       tick: {
  //         beginAtZero: true,
  //         callback: function (value) {
  //           return `£${value}k`;
  //         },
  //       },
  //     },
  //   },
  // };
  if (rewards > 0) max = rewards[rewards.length - 1].target;

  const options = {
    scales: {
      y: {
        position: "right",
        label: "Points",
        max: max,
        min: 0,
        ticks: {
          crossAlign: "far",
          maxTicksLimit: 18,
          fontStyle: "bold",
          callback: function (value) {
            var val = `    ${value / 1000}k`;
            var rew = rewards.filter((val, i) => {
              return parseInt(val.target) === value;
            });
            if (rew && rew.length > 0) val = `    ${rew[0].reward}- ${value}`;
            return val;
            // return [`Reward - ${value} Customers Served`];
          },
        },
      },
    },
  };

  const [modalLive, setModalLive] = React.useState(true);
  return (
    <>
      <Modal
        toggle={() => setModalLive(false)}
        isOpen={modalLive}
        style={{
          fontFamily: "Raleway",
          minWidth: window.innerWidth * 0.9,
          minHeight: window.innerHeight * 0.9,
        }}
      >
        <div className="modal-header">
          <div
            style={{ flex: 1, flexDirection: "center", alignItems: "center" }}
          >
            <center>
              <h5
                className="modal-title"
                id="exampleModalLiveLabel"
                style={{ fontSize: 48 }}
              >
                {department.rewards_title}
              </h5>
            </center>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModalLive(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div
            style={{
              position: "absolute",
              height: "84%", //window.innerHeight * 0.65,
              width: 30,
              top: 60,
              // paddingTop: 100,
              right: 120,
            }}
          >
            {/* <span
              style={{
                position: "absolute",
                bottom: "100%",
                left: 5,
              }}
            >
              100
            </span> */}
            {rewards.map((rew, i) => (
              <span key={i}>
                {rew.target <= max && (
                  <span
                    style={{
                      position: "absolute",
                      bottom:
                        (parseInt(rew.target) / parseInt(max)) * 100 + "%",
                      left: 5,
                    }}
                  >
                    <img src={rew.image} style={{ height: 30 }} />
                    {/* <b>{rew.reward}</b> - {rew.target} */}
                  </span>
                )}
              </span>
            ))}
          </div>

          <Line data={data} options={options} />
        </div>
        <div className="modal-footer">
          <div
            style={{
              flex: 0.4,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <div style={{ flexDirection: "row" }}>
              <img src="rating.png" style={{ width: 50, margin: 10 }} />
              <span style={{ fontFamily: "Raleway", fontSize: 16 }}>
                {department.month_reviews} x{" "}
                {parseInt(department.month_reviews) *
                  parseInt(department.review_points)}
              </span>
            </div>
            <div style={{ flexDirection: "row" }}>
              <img src="bad-review.png" style={{ width: 50, margin: 10 }} />
              <span style={{ fontFamily: "Raleway", fontSize: 16 }}>
                {parseInt(department.negative_points)}
              </span>
            </div>

            <Button
              style={{ backgroundColor: color }}
              type="button"
              onClick={() => setModalLive(false)}
            >
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

function csvJSON(csv) {
  var lines = csv.split("\n");

  var result = [];

  // NOTE: If your columns contain commas in their values, you'll need
  // to deal with those before doing the next step
  // (you might convert them to &&& or something, then covert them back later)
  // jsfiddle showing the issue https://jsfiddle.net/
  // var headers = lines[0].split(",");
  var headers = lines[0].split("\t");

  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    // var currentline = lines[i].split(",");
    var currentline = lines[i].split("\t");

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  //return result; //JavaScript object
  var json = result; //JSON.stringify(result); //JSON

  json.forEach((obj, index, array) => {
    var key,
      keys = Object.keys(obj);
    var n = keys.length;
    var newobj = {};
    while (n--) {
      key = keys[n];
      var newKey = key.charAt(0).toLowerCase() + key.slice(1);
      newKey = newKey.trim().replace(/ /g, "");
      newobj[newKey] = obj[key];
    }
    json[index] = newobj;
  });

  return json;
}

function hexToRGB(hex, alpha) {
  if (hex) {
    if (hex.length < 7) hex = hex + hex.replace("#", "");
    if (hex.indexOf("#FFF") > -1 || hex.indexOf("#fff") > -1)
      return "rgba(255, 255, 255, " + alpha + ")";
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }
}

function welcome() {
  var ndate = new Date();
  var hours = ndate.getHours();
  var message = hours < 12 ? "Morning" : hours < 18 ? "Afternoon" : "Evening";
  return message;
}

Number.prototype.leadingZeroes = function (len) {
  return (new Array(len).fill("0", 0).join("") + this).slice(-Math.abs(len));
};
