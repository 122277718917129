import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import axios from "axios";

import { csvJSON } from "utils/function";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
// pages for this kit
import Index from "views/Index.js";
// import NucleoIcons from "views/NucleoIcons.js";
// import LoginPage from "views/examples/LoginPage.js";
// import LandingPage from "views/examples/LandingPage.js";
// import ProfilePage from "views/examples/ProfilePage.js";
import WagesPage from "views/WagesPage";
import Loader from "react-loader-spinner";

const googleSheet =
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vQBY4JYVBJ7d2dEjFBL1UcYWojO6O_v49_PONeShDrb5LX85Aexy_0juU02Iajsx1OJP3yJH-zCHSVw/pub?output=tsv";

ReactDOM.render(<App />, document.getElementById("root"));
function App() {
  const [staffMembers, setStaffMembers] = React.useState([]);
  const [departments, setDepartments] = React.useState([]);
  const [settings, setSettings] = React.useState();
  const [loaded, setLoaded] = React.useState(false);

  const loadSettings = () => {
    const GOOGLE_FORM_ACTION_URL = googleSheet + "&gid=1465772122";
    // "https://spreadsheets.google.com/feeds/cells/1umo5dgBSYh1XMFI2t4WOHWdAQRd_nF9YCCVYhEYRk8s/3/public/full?alt=json";
    axios
      .get(GOOGLE_FORM_ACTION_URL)
      .then((res) => {
        var json = csvJSON(res.data);

        setSettings(json);
        setLoaded(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadStaffMembers = () => {
    const GOOGLE_FORM_ACTION_URL = googleSheet + "&gid=1638566635";
    // "https://spreadsheets.google.com/feeds/cells/1umo5dgBSYh1XMFI2t4WOHWdAQRd_nF9YCCVYhEYRk8s/3/public/full?alt=json";
    axios
      .get(GOOGLE_FORM_ACTION_URL)
      .then((res) => {
        var json = csvJSON(res.data);

        setStaffMembers(json);
        loadSettings();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadDepartments = () => {
    const GOOGLE_FORM_ACTION_URL = googleSheet + "&gid=1971440158";
    // "https://spreadsheets.google.com/feeds/cells/1umo5dgBSYh1XMFI2t4WOHWdAQRd_nF9YCCVYhEYRk8s/3/public/full?alt=json";
    axios
      .get(GOOGLE_FORM_ACTION_URL)
      .then((res) => {
        var json = [];
        csvJSON(res.data).map((dep, i) => {
          dep["quotes_in"] = dep.quotes_in
            .replace("[", "")
            .replace("]", "")
            .split(",");
          dep["quotes_out"] = dep.quotes_out
            .replace("[", "")
            .replace("]", "")
            .split(",");
          dep["gps"] = [dep.lat, dep.long];
          delete dep.lat;
          delete dep.long;
          json.push(dep);
        });

        setDepartments(json);
        loadStaffMembers();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    loadDepartments();
  }, []);

  if (!loaded)
    return (
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(./cafe.jpg)",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            // timeout={3000} //3 secs
            style={{ flex: 1, marginTop: "30%" }}
          />
        </div>
      </div>
    );

  return (
    <BrowserRouter>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,900&family=Shrikhand&display=swap');
      </style>

      <Switch>
        <Switch>
          <Route
            path="/index"
            render={(props) => (
              <Index
                {...props}
                staffMembers={staffMembers}
                googleSheet={googleSheet}
                departments={departments}
                settings={settings}
              />
            )}
          />
          {/* <Route
        path="/nucleo-icons"
        render={(props) => <NucleoIcons {...props} />}
      /> */}
          <Route
            path="/wages"
            render={(props) => (
              <WagesPage
                {...props}
                staffMembers={staffMembers}
                googleSheet={googleSheet}
                departments={departments}
                settings={settings}
              />
            )}
          />
          {/* <Route
        path="/profile-page"
        render={(props) => <ProfilePage {...props} />}
      />
      <Route
        path="/login-page"
        render={(props) => <LoginPage {...props} />}
      /> */}
          <Redirect to="/index" />
          <Redirect from="/" to="/index" />
        </Switch>
      </Switch>
    </BrowserRouter>
  );
}
