import React from "react";
import axios from "axios";
import classNames from "classnames";
// reactstrap components
// import {
// } from "reactstrap";

import { Alert, Container } from "reactstrap";
import PinField from "react-pin-field";

// core components
// import IndexNavbar from "components/Navbars/IndexNavbar.js";
import StaffPage, { ModalChart } from "./StaffPage";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { csvJSON } from "utils/function";
// import DarkFooter from "components/Footers/DarkFooter.js";

// sections for this page
// import Images from "./index-sections/Images.js";
// import BasicElements from "./index-sections/BasicElements.js";
// import Navbars from "./index-sections/Navbars.js";
// import Tabs from "./index-sections/Tabs.js";
// import Pagination from "./index-sections/Pagination.js";
// import Notifications from "./index-sections/Notifications.js";
// import Typography from "./index-sections/Typography.js";
// import Javascript from "./index-sections/Javascript.js";
// import Carousel from "./index-sections/Carousel.js";
// import NucleoIcons from "./index-sections/NucleoIcons.js";
// import CompleteExamples from "./index-sections/CompleteExamples.js";
// import SignUp from "./index-sections/SignUp.js";
// import Examples from "./index-sections/Examples.js";
// import Download from "./index-sections/Download.js";

function Index(props) {
  const [loc, setLoc] = React.useState([]);
  const [cam, setCam] = React.useState(false);
  const [pin, setPin] = React.useState();
  const [name, setName] = React.useState();
  const [nickname, setNickname] = React.useState();
  const [user, setUser] = React.useState();
  const [image, setImage] = React.useState();
  const [bg, setBG] = React.useState("./cafe.jpg");
  const [color, setColor] = React.useState("blue");
  const [logo, setLogo] = React.useState("./cc.png");
  const [department, setDepartment] = React.useState();
  const [latestIn, setLatestIn] = React.useState();

  const [latestOut, setLatestOut] = React.useState();
  const [staffMembers, setStaffMembers] = React.useState(props.staffMembers);
  const [googleSheet, setGoogleSheet] = React.useState(props.googleSheet);

  // const [code, setCode] = React.useState("");
  const [completed, setCompleted] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  React.useEffect(() => {
    if ("geolocation" in navigator && loc.length === 0) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLoc([position.coords.latitude, position.coords.longitude]);
      });
    }
    if (
      typeof navigator === "undefined" ||
      typeof navigator.getUserMedia === "undefined"
    ) {
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: "user",
          },
        })
        .then(function success(stream) {
          setCam(true);
        });
    } else {
      navigator.getUserMedia(
        { audio: false, video: true },
        function (stream) {
          stream.getTracks().forEach((x) => x.stop());
          setCam(true);
        },
        (err) => console.log(err)
      );
    }

    loadLatestIn();
    loadLatestOut();
    // setTimeout(() => {
    //   checkPin((2005).toString());
    // }, 500);
  }, []);

  function setCamera() {
    if (
      typeof navigator === "undefined" ||
      typeof navigator.getUserMedia === "undefined"
    ) {
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: "user",
          },
        })
        .then(function success(stream) {
          setCam(true);
        });
    } else {
      navigator.getUserMedia(
        { audio: false, video: true },
        function (stream) {
          stream.getTracks().forEach((x) => x.stop());
          setCam(true);
        },
        (err) => console.log(err)
      );
    }
  }

  function loadLatestIn() {
    const GOOGLE_FORM_ACTION_URL = googleSheet + "&gid=294987067";
    // "https://spreadsheets.google.com/feeds/cells/1umo5dgBSYh1XMFI2t4WOHWdAQRd_nF9YCCVYhEYRk8s/3/public/full?alt=json";
    axios
      .get(GOOGLE_FORM_ACTION_URL)
      .then((res) => {
        var json = csvJSON(res.data);
        setLatestIn(json);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  // function loadLatestIn() {
  //   const GOOGLE_FORM_ACTION_URL = googleSheet + "&gid=294987067";
  //   // "https://spreadsheets.google.com/feeds/cells/1umo5dgBSYh1XMFI2t4WOHWdAQRd_nF9YCCVYhEYRk8s/3/public/full?alt=json";
  //   axios
  //     .get(GOOGLE_FORM_ACTION_URL)
  //     .then((res) => {
  //       var json = csvJSON(res.data);

  //       setLatestIn(json[json.length]);

  // var clock_in = [];
  // var obj = {};

  // var prev = 2;
  // var col = 0;
  // var row = 0;
  // res.data.feed.entry.map((item, index, array) => {
  //   row = parseInt(item.gs$cell.row);
  //   col = parseInt(item.gs$cell.col);
  //   if (row > 1) {
  //     // var split_string = item.title.$t.split(/(\d+)/);

  //     if (prev !== row) {
  //       clock_in.push(obj);
  //       obj = {};
  //     }

  //     if (col === 1) {
  //       // var date = new Date(
  //       //   item.content.$t.split("/")[1] +
  //       //     "/" +
  //       //     item.content.$t.split("/")[0] +
  //       //     "/" +
  //       //     item.content.$t.split("/")[2]
  //       // );
  //       obj["timestamp"] = new Date(item.content.$t);
  //     } else if (col === 2) {
  //       obj["name"] = item.content.$t;
  //     } else if (col === 3 && item.content.$t.indexOf("[") > -1) {
  //       obj["location"] = JSON.parse(item.content.$t);
  //     } else if (col === 4) {
  //       obj["department"] = item.content.$t;
  //     } else if (col === 5) {
  //       obj["image"] = item.content.$t;
  //     }
  //     prev = row;
  //   }
  // });
  // setLatestIn(clock_in);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  function loadLatestOut() {
    const GOOGLE_FORM_ACTION_URL = googleSheet + "&gid=684147137";
    // "https://spreadsheets.google.com/feeds/cells/1umo5dgBSYh1XMFI2t4WOHWdAQRd_nF9YCCVYhEYRk8s/3/public/full?alt=json";
    axios
      .get(GOOGLE_FORM_ACTION_URL)
      .then((res) => {
        var json = csvJSON(res.data);

        setLatestOut(json);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // function loadLatestOut() {
  //   const GOOGLE_FORM_ACTION_URL =
  //     "https://spreadsheets.google.com/feeds/cells/1umo5dgBSYh1XMFI2t4WOHWdAQRd_nF9YCCVYhEYRk8s/4/public/full?alt=json";
  //   axios
  //     .get(GOOGLE_FORM_ACTION_URL)
  //     .then((res) => {
  //       var clock_out = [];
  //       var obj = {};

  //       var prev = 2;
  //       var col = 0;
  //       var row = 0;
  //       res.data.feed.entry.map((item, index, array) => {
  //         row = parseInt(item.gs$cell.row);
  //         col = parseInt(item.gs$cell.col);
  //         if (row > 1) {
  //           // var split_string = item.title.$t.split(/(\d+)/);

  //           if (prev !== row) {
  //             clock_out.push(obj);
  //             obj = {};
  //           }

  //           if (col === 1) {
  //             var date = new Date(
  //               item.content.$t.split("/")[1] +
  //                 "/" +
  //                 item.content.$t.split("/")[0] +
  //                 "/" +
  //                 item.content.$t.split("/")[2]
  //             );
  //             obj["timestamp"] = date;
  //           } else if (col === 2) {
  //             obj["name"] = item.content.$t;
  //           } else if (col === 3 && item.content.$t.indexOf("[") > -1) {
  //             obj["location"] = JSON.parse(item.content.$t);
  //           } else if (col === 4) {
  //             obj["department"] = item.content.$t;
  //           } else if (col === 5) {
  //             obj["image"] = item.content.$t;
  //           }
  //           prev = row;
  //         }
  //       });
  //       setLatestOut(clock_out);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  function setLocation() {
    if (loc.length === 0) {
      console.log("Available", navigator.geolocation);
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("position", position);
        setLoc([position.coords.latitude, position.coords.longitude]);
      });
    } else {
      console.log("Got them", loc);
    }
  }

  function checkPin(pin) {
    if (staffMembers.length > 0) {
      var success = staffMembers.filter((member, index) => {
        return member.pin === pin;
      });
      if (success && success.length > 0) {
        var dep = props.departments.filter(
          (dep, i) => dep.name === success[0].department
        )[0];
        setUser(success[0]);
        setName(success[0].name);
        setNickname(success[0].nickname);
        setImage(success[0].image);
        setBG(dep && dep.image);
        setColor(dep && dep.color);
        setLogo(success[0].logo);
        setDepartment(dep);
        setPin(pin);
        setCompleted(true);
      }
    } else {
      setTimeout(() => {
        var success = staffMembers.filter((member, index) => {
          return member.pin === pin;
        });
        if (success && success.length > 0) {
          var dep = props.departments.filter(
            (dep, i) => dep.name === success[0].department
          )[0];
          console.log(success);
          setUser(success[0]);
          setName(success[0].name);
          setNickname(success[0].nickname);
          setImage(success[0].image);
          setBG(dep && dep.image);
          setColor(dep && dep.color);
          setLogo(success[0].logo);
          setDepartment(dep);
          setPin(pin);
          setCompleted(true);
        }
      }, 1500);
    }
  }

  if (!pin) {
    return (
      <>
        <div className="page-header clear-filter" filter-color="blue">
          <div
            className="page-header-image"
            style={{
              backgroundImage: "url(./cafe.jpg)",
            }}
          ></div>
          <div className="content">
            <Container>
              <div
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  textAlign: "center",
                  zIndex: 100000,
                }}
              >
                <div className="logo-container">
                  <img
                    alt="..."
                    src={require("assets/img/favicon.png")}
                    style={{ width: 100 }}
                  ></img>
                </div>
                <span
                  style={{
                    fontFamily: "Raleway",
                    fontSize: window.innerWidth < 480 ? 48 : 72,
                  }}
                >
                  {props.settings.welcome_message
                    ? props.settings.welcome_message
                    : "WELCOME"}
                  <br />
                </span>
                <span
                  style={{
                    fontFamily: "Raleway",
                    fontSize: window.innerWidth < 480 ? 32 : 36,
                  }}
                >
                  {props.settings.welcome_message_sub
                    ? props.settings.welcome_message_sub
                    : "Put your birth date below"}
                  <br /> {"(Day and Month)"}
                  <br />
                </span>
                <PinField
                  className={classNames("field-a", {
                    "field-a-complete": completed,
                  })}
                  type="number"
                  min="0"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  style={{ marginTop: "10%" }}
                  validate="0123456789"
                  length={4}
                  onComplete={(e) => checkPin(e)}
                  format={(k) => k.toUpperCase()}
                  autoFocus
                  disabled={completed}
                />
              </div>
            </Container>
          </div>
        </div>
      </>
    );
  }

  if (loc.length === 0)
    return (
      <>
        <div className="page-header clear-filter" filter-color="blue">
          <Alert color="danger" isOpen={true}>
            <Container>
              <div className="alert-icon">
                <i className="now-ui-icons objects_support-17"></i>
              </div>
              <strong>Enable Location</strong> Change your location permission
              in order to clock in.
              <button
                type="button"
                className="close"
                onClick={() => setLocation()}
              >
                <span aria-hidden="true">
                  <i className="now-ui-icons ui-1_simple-remove"></i>
                </span>
              </button>
            </Container>
          </Alert>
          <div
            className="page-header-image"
            style={{
              backgroundImage: "url(./cafe.jpg)",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              // timeout={3000} //3 secs
              style={{ flex: 1, marginTop: "20%" }}
            />
          </div>
        </div>
      </>
    );

  if (!cam)
    return (
      <>
        <div className="wrapper">
          <Alert color="danger" isOpen={true}>
            <Container>
              <div className="alert-icon">
                <i className="now-ui-icons objects_support-17"></i>
              </div>
              <strong>Enable Camera</strong> Change your camera permissions in
              order to clock in.
              <button
                type="button"
                className="close"
                onClick={() => setCamera()}
              >
                <span aria-hidden="true">
                  <i className="now-ui-icons ui-1_simple-remove"></i>
                </span>
              </button>
            </Container>
          </Alert>
        </div>
      </>
    );

  if (!latestIn || !latestOut) {
    return (
      <>
        <div className="page-header clear-filter" filter-color="blue">
          <div
            className="page-header-image"
            style={{
              backgroundImage: "url(./cafe.jpg)",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              textAlign: "center",
            }}
          >
            <Loader
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              // timeout={3000} //3 secs
              style={{ flex: 1, marginTop: "20%" }}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {/* <IndexNavbar /> */}
      <div className="wrapper">
        <StaffPage
          user={user}
          name={name}
          nickname={nickname}
          image={image}
          bg={bg}
          color={color}
          logo={logo}
          googleSheet={googleSheet}
          departments={props.departments}
          department={department}
          settings={props.settings}
          loc={loc}
          latestIn={latestIn}
          latestOut={latestOut}
        />
        {/* <div className="main"> */}
        {/* <Images /> */}
        {/* <BasicElements />
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications /> */}
        {/* <Typography />
          <Javascript /> */}
        {/* <Carousel />
          <NucleoIcons />
          <CompleteExamples />
          <SignUp />
          <Examples />
          <Download /> */}
        {/* </div> */}
      </div>
    </>
  );
}

// function StaffPin(props) {
//   return (
//     <PinField
//       className={classNames("field-a", { "field-a-complete": demoCompleted })}
//       onComplete={() => setDemoCompleted(true)}
//       format={(k) => k.toUpperCase()}
//       autoFocus
//       disabled={demoCompleted}
//     />
//   );
// }

export default Index;
