import React from "react";
import bcrypt from "bcryptjs";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  FormGroup,
  Table,
} from "reactstrap";

import Datetime from "react-datetime";
import moment from "moment";

import axios from "axios";
import { csvJSON } from "utils/function";

var now = new Date();

// 7964b1638402b19bcc4ff7a3f0f846d9

// var salt = bcrypt.genSaltSync(10);
// bcrypt.hashSync("", salt);

const googleSheet =
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vQBY4JYVBJ7d2dEjFBL1UcYWojO6O_v49_PONeShDrb5LX85Aexy_0juU02Iajsx1OJP3yJH-zCHSVw/pub?output=tsv";
var hash = "$2a$10$/XVtmVniBA/ab3lNV1ktyO1lyQ404BF5OtAgZSAGw2Y1Um0Pha8Wi";
var user = "Sean";
// vietnam08#

function WagesPage(props) {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [access, setAccess] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [bonus, setBonus] = React.useState(0);
  const [days, setDays] = React.useState(14);
  const [date, setDate] = React.useState(now);
  const [future, setFuture] = React.useState(now);
  const [staff, setStaff] = React.useState("all");
  const [json, setJSON] = React.useState([]);
  const [staffMembers, setStaffMembers] = React.useState(props.staffMembers);
  const [googleSheet, setGoogleSheet] = React.useState(props.googleSheet);

  function loadClockIns() {
    const GOOGLE_FORM_ACTION_URL = googleSheet + "&gid=0";
    // "https://spreadsheets.google.com/feeds/cells/1umo5dgBSYh1XMFI2t4WOHWdAQRd_nF9YCCVYhEYRk8s/3/public/full?alt=json";
    axios
      .get(GOOGLE_FORM_ACTION_URL)
      .then((res) => {
        // timestamp	Name	location	department	image
        var clock_in = csvJSON(res.data);
        clock_in.map((item, index, array) => {
          console.log(item);
          var date = new Date(item.timestamp);
          clock_in[index]["clockin"] = date;
        });

        loadClockOuts(clock_in);
        setJSON(clock_in);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function loadClockIns2() {
    const GOOGLE_FORM_ACTION_URL =
      "https://spreadsheets.google.com/feeds/cells/1umo5dgBSYh1XMFI2t4WOHWdAQRd_nF9YCCVYhEYRk8s/1/public/full?alt=json";
    axios
      .get(GOOGLE_FORM_ACTION_URL)
      .then((res) => {
        // console.log(res.data.feed.entry);
        var clock_in = [];
        var obj = {};
        var prev = 2;
        var col = 0;
        var row = 0;
        res.data.feed.entry.map((item, index, array) => {
          row = parseInt(item.gs$cell.row);
          col = parseInt(item.gs$cell.col);

          if (row > 1) {
            // var split_string = item.title.$t.split(/(\d+)/);
            if (prev !== row) {
              clock_in.push(obj);
              obj = { selected: false, hours: 0, distance: 0 };
            }

            if (col === 1) {
              var date = new Date(item.content.$t);
              obj["clockin"] = date;
            } else if (col === 2) {
              obj["name"] = item.content.$t;
            } else if (col === 3 && item.content.$t.indexOf("[") > -1) {
              obj["location"] = JSON.parse(item.content.$t);
            } else if (col === 4) {
              obj["department"] = item.content.$t;
            } else if (col === 5) {
              obj["image"] = item.content.$t;
            }
            prev = row;
          }
        });
        setJSON(clock_in);
        loadClockOuts(clock_in);
        // setAccess(true);
      })
      .catch((e) => {});
  }
  function loadClockOuts(clock_in) {
    const GOOGLE_FORM_ACTION_URL = googleSheet + "&gid=1346914229";
    // "https://spreadsheets.google.com/feeds/cells/1umo5dgBSYh1XMFI2t4WOHWdAQRd_nF9YCCVYhEYRk8s/3/public/full?alt=json";
    axios
      .get(GOOGLE_FORM_ACTION_URL)
      .then((res) => {
        var clock_out = csvJSON(res.data);
        clock_out.map((item, index, array) => {
          // var date = new Date(item.timestamp);

          var date = new Date(
            item.timestamp.split("/")[1] +
              "/" +
              item.timestamp.split("/")[0] +
              "/" +
              item.timestamp.split("/")[2]
          );
          clock_out[index]["clockout"] = date;
        });

        // timestamp	Name	location	department	image

        clock_in.map((item, index, array) => {
          // console.log(item);
          var ob = clock_out.filter((out, i, array) => {
            //

            if (out.name === item.name && !out.selected) {
              var in_date = new Date(item.clockin).setHours(0, 0, 0, 0);
              var out_date = new Date(out.timestamp).setHours(0, 0, 0, 0);

              return in_date === out_date;
            }
            // return (
            //   a.timestamp &&
            //   a.name === staff &&
            //   a.timestamp > date &&
            //   a.timestamp < future
            // );
          });
          console.log(ob);
          if (ob.length > 0) {
            if (ob[0].clockout.gps) {
              var gps = [10.8087144, 106.7275147];
              var dept_temp = props.departments.filter(
                (dept, index, array) => dept.name === item.department
              );
              if (dept_temp.length > 0) gps = dept_temp[0].gps;
              clock_in[index]["distance"] = distance(gps, ob[0].clockout.gps);
            }
            clock_in[index]["clockout"] = ob[0].clockout;
            clock_in[index]["hours"] =
              Math.abs(ob[0].clockout - item.clockin) / 36e5;
          }
        });
        // console.log(json);
        // console.log(clock_in);
        setJSON(clock_in);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  function loadClockOuts2(clock_in) {
    const GOOGLE_FORM_ACTION_URL =
      "https://spreadsheets.google.com/feeds/cells/1umo5dgBSYh1XMFI2t4WOHWdAQRd_nF9YCCVYhEYRk8s/2/public/full?alt=json";
    axios
      .get(GOOGLE_FORM_ACTION_URL)
      .then((res) => {
        // console.log(res.data.feed.entry);
        var clock_out = [];
        var obj = {};
        var prev = 2;
        var col = 0;
        var row = 0;
        res.data.feed.entry.map((item, index, array) => {
          row = parseInt(item.gs$cell.row);
          col = parseInt(item.gs$cell.col);

          if (row > 1) {
            // var split_string = item.title.$t.split(/(\d+)/);
            if (prev !== row) {
              clock_out.push(obj);
              obj = { selected: false };
            }

            if (col === 1) {
              // var date = new Date(item.content.$t);
              var date = new Date(
                item.content.$t.split("/")[1] +
                  "/" +
                  item.content.$t.split("/")[0] +
                  "/" +
                  item.content.$t.split("/")[2]
              );
              obj["clockout"] = date;
            } else if (col === 2) {
              obj["name"] = item.content.$t;
            } else if (col === 3 && item.content.$t.indexOf("[") > -1) {
              obj["location"] = JSON.parse(item.content.$t);
            } else if (col === 4) {
              obj["department"] = item.content.$t;
            } else if (col === 5) {
              obj["image"] = item.content.$t;
            }
            prev = row;
          }
        });
        // setJSON(clock_out);

        //  Loop throught click in array
        //  find staff member in
        //

        clock_in.map((item, index, array) => {
          // console.log(item);
          var ob = clock_out.filter((out, i, array) => {
            //
            if (out.name === item.name && !out.selected) {
              var in_date = new Date(item.clockin).setHours(0, 0, 0, 0);
              var out_date = new Date(out.clockout).setHours(0, 0, 0, 0);

              return in_date === out_date;
            }
            // return (
            //   a.timestamp &&
            //   a.name === staff &&
            //   a.timestamp > date &&
            //   a.timestamp < future
            // );
          });
          if (ob.length > 0) {
            if (ob[0].clockout.gps) {
              var gps = [10.8087144, 106.7275147];
              var dept_temp = props.departments.filter(
                (dept, index, array) => dept.name === item.department
              );
              if (dept_temp.length > 0) gps = dept_temp[0].gps;
              clock_in[index]["distance"] = distance(gps, ob[0].clockout.gps);
            }
            clock_in[index]["clockout"] = ob[0].clockout;
            clock_in[index]["hours"] =
              Math.abs(ob[0].clockout - item.clockin) / 36e5;
          }
        });
        // console.log(clock_out);
        console.log(clock_in);
        setJSON(clock_in);
        // setAccess(true);
      })
      .catch((e) => {});
  }
  function distance(lon1, lat1, lon2, lat2) {
    var R = 6371; // Radius of the earth in km
    var dLat = (lat2 - lat1).toRad(); // Javascript functions in radians
    var dLon = (lon2 - lon1).toRad();
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1.toRad()) *
        Math.cos(lat2.toRad()) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    console.log(
      "distance",
      d,
      " [" + lon1 + "," + lat1 + "][" + lon2 + "," + lat2 + "]"
    );
    return d;
  }
  function verify() {
    bcrypt.compare(password, hash, function (err, res) {
      if (username === user && res === true) {
        setAccess(true);
        loadClockIns();
      }
      // res == true
    });
  }

  React.useEffect(() => {
    loadClockIns();

    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundimage: "url(" + require("assets/img/login.jpg") + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            {!access ? (
              <Col className="ml-auto mr-auto" md="4">
                <Card className="card-login card-plain">
                  <Form action="" className="form" method="">
                    <CardHeader className="text-center">
                      <div className="logo-container">
                        <img
                          alt="..."
                          src={require("assets/img/favicon.png")}
                          style={{ width: 100 }}
                        ></img>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Username"
                          type="text"
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                          onChange={(e) => setUsername(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (lastFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password..."
                          type="password"
                          onFocus={() => setLastFocus(true)}
                          onBlur={() => setLastFocus(false)}
                          onChange={(e) => setPassword(e.target.value)}
                        ></Input>
                      </InputGroup>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        block
                        className="btn-round"
                        color="info"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          verify();
                        }}
                        size="lg"
                        disabled={
                          password !== "" && username !== "" ? false : true
                        }
                      >
                        Login
                      </Button>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            ) : (
              <div>
                <Col className="ml-auto mr-auto" md="4">
                  <Card className="card-login card-plain">
                    <CardHeader className="text-center">
                      <div className="logo-container">
                        <img
                          alt="..."
                          src={require("assets/img/favicon.png")}
                          style={{ width: 100 }}
                        ></img>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md="12">
                          <div className="datepicker-container">
                            <FormGroup>
                              <Datetime
                                onChange={(e) => {
                                  setDate(new Date(e));
                                  var dt = new Date(e);
                                  dt.setDate(dt.getDate() + days);
                                  console.log(new Date(e), dt);
                                  setFuture(dt);
                                }}
                                initialValue={moment().format()}
                                timeFormat={false}
                                inputProps={{
                                  placeholder: "Datetime Picker Here",
                                }}
                              />
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <select
                          id=""
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0.1)",
                            borderTopRightRadius: 25,
                            borderBottomRightRadius: 25,
                            border: "none",
                            fontSize: 14,
                            color: "#fff",
                            width: "80%",
                          }}
                          onChange={(e) => {
                            setStaff(e.target.value);
                          }}
                        >
                          <option value="all">All Staff Members</option>
                          {staffMembers.map((item, i) => (
                            <option value={item.name} key={i}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (lastFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Days"
                          type="number"
                          value={days}
                          onFocus={() => setLastFocus(true)}
                          onBlur={() => setLastFocus(false)}
                          onChange={(e) => setDays(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (lastFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Bonus"
                          type="number"
                          onFocus={() => setLastFocus(true)}
                          onBlur={() => setLastFocus(false)}
                          onChange={(e) => setBonus(e.target.value)}
                        ></Input>
                      </InputGroup>
                    </CardBody>
                  </Card>
                </Col>
                {json.length > 0 && (
                  <StaffTable
                    json_filtered={json.filter((a, i, array) => {
                      return (
                        (a.clockin &&
                          a.name === staff &&
                          a.clockin > date &&
                          a.clockin < future) ||
                        staff === "all"
                      );
                    })}
                  />
                )}
              </div>
            )}
          </Container>
        </div>
      </div>
    </>
  );
}

export default WagesPage;

function StaffTable(props) {
  if (props.json_filtered.length === 0) return <></>;

  return (
    <div
      style={{
        width: "90%",
        maxHeight: 900,
        overflowY: "auto",
      }}
    >
      <Table bordered style={{ color: "#fff" }} height={800}>
        <thead>
          <tr>
            <th>Clock In</th>
            <th>Clock Out</th>
            <th>Hours</th>
            <th>Name</th>
            <th>department</th>
            <th>location</th>
            <th>image</th>
          </tr>
        </thead>
        <tbody>
          {props.json_filtered.map((item, index, array) => (
            <tr key={index}>
              <th scope="row">{item.clockin.toLocaleString()}</th>
              <th scope="row">
                {item.clockout && item.clockout.toLocaleString()}
              </th>
              <th scope="row">{item.hours && item.hours.toFixed(2)}</th>
              <td>{item.name}</td>
              <td>{item.department}</td>
              <td>
                {item.location && item.location.length > 0 && (
                  <img
                    src={
                      "https://maps.googleapis.com/maps/api/staticmap?center=" +
                      item.location[0] +
                      "," +
                      item.location[1] +
                      "&zoom=14&markers=icon:/images/stores/cheesecake-ngon/cheesecake-ngon-logo.png|" +
                      item.location[0] +
                      "," +
                      item.location[1] +
                      "&size=100x100&style=feature%3Aadministrative%7Celement%3Ageometry.fill%7Ccolor%3A0xd6e2e6&style=feature%3Aadministrative%7Celement%3Ageometry.stroke%7Ccolor%3A0xcfd4d5&style=feature%3Aadministrative%7Celement%3Alabels.text.fill%7Ccolor%3A0x7492a8&style=feature%3Aadministrative.neighborhood%7Celement%3Alabels.text.fill%7Clightness%3A25&style=feature%3Alandscape.man_made%7Celement%3Ageometry.fill%7Ccolor%3A0xdde2e3&style=feature%3Alandscape.man_made%7Celement%3Ageometry.stroke%7Ccolor%3A0xcfd4d5&style=feature%3Alandscape.natural%7Celement%3Ageometry.fill%7Ccolor%3A0xdde2e3&style=feature%3Alandscape.natural%7Celement%3Alabels.text.fill%7Ccolor%3A0x7492a8&style=feature%3Alandscape.natural.terrain%7Cvisibility%3Aoff&style=feature%3Apoi%7Celement%3Ageometry.fill%7Ccolor%3A0xdde2e3&style=feature%3Apoi%7Celement%3Alabels.icon%7Csaturation%3A-100&style=feature%3Apoi%7Celement%3Alabels.text.fill%7Ccolor%3A0x588ca4&style=feature%3Apoi.park%7Celement%3Ageometry.fill%7Ccolor%3A0xa9de83&style=feature%3Apoi.park%7Celement%3Ageometry.stroke%7Ccolor%3A0xbae6a1&style=feature%3Apoi.sports_complex%7Celement%3Ageometry.fill%7Ccolor%3A0xc6e8b3&style=feature%3Apoi.sports_complex%7Celement%3Ageometry.stroke%7Ccolor%3A0xbae6a1&style=feature%3Aroad%7Celement%3Alabels.icon%7Csaturation%3A-45%7Clightness%3A10%7Cvisibility%3Aon&style=feature%3Aroad%7Celement%3Alabels.text.fill%7Ccolor%3A0x41626b&style=feature%3Aroad.arterial%7Celement%3Ageometry.fill%7Ccolor%3A0xffffff&style=feature%3Aroad.highway%7Celement%3Ageometry.fill%7Ccolor%3A0xc1d1d6&style=feature%3Aroad.highway%7Celement%3Ageometry.stroke%7Ccolor%3A0xa6b5bb&style=feature%3Aroad.highway%7Celement%3Alabels.icon%7Cvisibility%3Aon&style=feature%3Aroad.highway.controlled_access%7Celement%3Ageometry.&key=AIzaSyB5gGiL2GULOD3iUxLhVxNjWfHBQbrkaoE"
                    }
                  />
                )}
                <br />
                {item.distance}
              </td>
              <td>
                {item.image && (
                  <img
                    src={item.image} //.substring(1, item.image.length - 1)}
                    style={{ width: 100 }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>
              {props.json_filtered
                .reduce((a, b, i) => {
                  console.log(a, b.hours);
                  return a + b.hours;
                }, 0)
                .toFixed(2)}
            </th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
}
